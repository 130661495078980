<template>
  <v-app>
    <v-app-bar
      app
      class="light-green darken-2"
      dark
    >
      <div>
        <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Menu
          </v-btn>
        </template>
        <v-list>
          <v-list-item href="/scan-qr">
            <v-list-item-title>Skanuj QR</v-list-item-title>
          </v-list-item>
          <v-list-item href="/shop">
            <v-list-item-title>Sklep</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>


      <v-spacer></v-spacer>

      <span class="mr-2">Owca QR</span>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
